<template>
  <div class="container">
    <div class="hero">
      <div class="hero-body">
        <h1 class="title is-4 has-text-white">Schwarztöne</h1>
        <div class="has-text-white" v-for="(color, index) in allColorSets" :key="color">
          <span class="has-text-primary">{{ index + 1 }}:</span> {{ color }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SchwarztöneView',
  data: () => ({
    colorNames: [
      'Azul Alicia',
      'Antikweiß',
      'Wasser',
      'Aquamarinblau',
      'Himmelblau',
      'Beige',
      'Biskuit',
      'Schwarz',
      'Mandelweiß',
      'Blau',
      'Blauviolett',
      'Braun',
      'Gelbbraun',
      'Kadettenblau',
      'Hellgrün',
      'Schokolade',
      'Koralle',
      'Kornblumenblau',
      'Mais',
      'Karmesinrot',
      'Türkis',
      'Dunkelblau',
      'Dunkeltürkis',
      'Dunkle Goldrutenfarbe',
      'Dunkelgrün',
      'Dunkelgrau',
      'Dunkelkhaki',
      'Dunkelmagenta',
      'Dunkles Olivgrün',
      'Dunkles Orange',
      'Dunkle Orchidee',
      'Dunkelrot',
      'Dunkle Lachsfarbe',
      'Dunkles Seegrün',
      'Dunkles Schieferblau',
      'Dunkles Schiefergrau',
      'Dunkeltürkis',
      'Dunkelviolett',
      'Tiefrosa',
      'Tiefes Himmelblau',
      'Trübes Grau',
      'Persenningblau',
      'Backstein',
      'Blütenweiß',
      'Waldgrün',
      'Honigmelone',
      'Leuchtendes Rosa',
      'Indischrot',
      'Indigo',
      'Elfenbein',
      'Staubfarben',
      'Lavendel',
      'Lavendelrosa',
      'Rasengrün',
      'Chiffongelb',
      'Hellblau',
      'Helles Korallenrot',
      'Helles Cyan',
      'Helles Goldrutengelb',
      'Hellgrau',
      'Hellgrün',
      'Hellrosa',
      'Helle Lachsfarbe',
      'Helles Seegrün',
      'Helles Himmelblau',
      'Helles Schiefergrau',
      'Helles Stahlblau',
      'Hellgelb',
      'Limone',
      'Limonengrün',
      'Leinen',
      'Magenta',
      'Kastanie',
      'Mittleres Aquamarin',
      'Mittleres Blau',
      'Mittlere Orchidee',
      'Mittleres Violett',
      'Mittleres Seegrün',
      'Mittleres Schieferblau',
      'Mittleres Frühlingsgrün',
      'Mittlere Türkis',
      'Mittleres Violettrot',
      'Mitternachtsblau',
      'Cremige Minze',
      'Altrosa',
      'Mokassin',
      'Navajoweiß',
      'Marineblau',
      'Alte Spitze',
      'Olivgrün',
      'Olivgraubraun',
      'Orange',
      'Orangerot',
      'Orchidee',
      'Blasse Goldrutenfarbe',
      'Blassgrün',
      'Blasstürkis',
      'Blasses Violettrot',
      'Papayacreme',
      'Pfirsich',
      'Peru',
      'Rosa',
      'Pflaume',
      'Taubenblau',
      'Purpur',
      'Rot',
      'Rosiges Braun',
      'Königsblau',
      'Sattelbraun',
      'Lachsfarben',
      'Sandbraun',
      'Seegrün',
      'Muschel',
      'Siennaerde',
      'Silber',
      'Himmelblau',
      'Schieferblau',
      'Schiefergrau',
      'Schneeweiß',
      'Frühlingsgrün',
      'Stahlblau',
      'Hautfarben',
      'Krickentengrün',
      'Distel',
      'Tomate',
      'Türkis',
      'Veilchen',
      'Weizen',
      'Weiß',
      'Rauchfarben',
      'Gelb',
      'Gelbgrün',
    ],
  }),
  computed: {
    allColorSets() {
      let colorSets = [];
      let colorSets2 = [];

      this.colorNames.forEach((color) => {
        colorSets.push(`${color}-Schwarz`);
      });

      this.colorNames.forEach((color) => {
        this.colorNames.forEach((color2) => {
          colorSets2.push(`${color}-${color2}-Schwarz`);
        });
      });

      colorSets2 = colorSets2.sort((a, b) => a.length - b.length);

      //switch every third color with a random color
      let colorSetIndex = 0;

      for (let i = 0; i < colorSets2.length; i++) {
        if (i % 4 === 0) {
          colorSetIndex = Math.ceil(Math.random() * colorSets2.length) - 1;

          const tempColor = colorSets2[i];
          colorSets2[i] = colorSets2[colorSetIndex];
          colorSets2[colorSetIndex] = tempColor;
        }
      }

      return colorSets.concat(colorSets2);
    },
  },
};
</script>
