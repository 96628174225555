var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"has-background-white"},[_c('section',{staticClass:"hero is-medium has-gridlines"},[_c('div',{staticClass:"hero-body is-relative"},[_c('div',[_c('div',{staticClass:"columns is-multiline is-gapless"},[_c('div',{attrs:{"id":"sc-valley__img-1-start-trigger"}}),_c('div',{staticClass:"column is-full is-half-desktop pt-6"},[_c('mq-layout',{attrs:{"mq":['mobile', 'tablet', 'desktop', 'widescreen']}},[(['tablet'].includes(_vm.$mq))?_c('div',{staticClass:"section-two"}):_vm._e(),_c('div',{staticClass:"title is-6 has-text-right-desktop"},[_vm._v(" Während er sein Glück vermutlich im "),_c('span',{staticClass:"has-text-primary is-family-secondary"},[_vm._v("Silicon Valley")]),_vm._v(" versucht... ")])]),_c('mq-layout',{attrs:{"mq":['fullhd']}},[_c('text-slide',{attrs:{"animated-text":_vm.heroText1}})],1)],1),_c('div',{staticClass:"column is-full is-half-desktop"},[_c('div',{class:{
                'px-6': ['desktop', 'widescreen', 'fullhd'].includes(_vm.$mq),
                'py-6': ['mobile', 'tablet'].includes(_vm.$mq),
              }},[_c('div',{staticClass:"is-retro is-primary",style:(`${!['mobile', 'tablet', 'desktop', 'widescreen'].includes(_vm.$mq) && 'width: 80%'}`),attrs:{"id":"sc-valley__img-1"}},[_c('b-image',{attrs:{"src":require('@/assets/img/silicon-valley.png'),"ratio":"is4by3","alt":"Silicon Valley - Karte"}})],1)])]),_c('div',{attrs:{"id":"sc-valley__img-1-end-trigger"}})])])])]),(['widescreen', 'fullhd'].includes(_vm.$mq))?_c('div',{staticClass:"section-two"}):_vm._e(),_c('section',{staticClass:"hero is-white has-gridlines",class:['fullhd'].includes(_vm.$mq) ? 'is-large' : 'is-small'},[_c('div',{staticClass:"hero-body"},[_c('div',{attrs:{"id":"sc-valley__img-2-start-trigger"}}),_c('div',{staticClass:"columns is-multiline"},[_c('mq-layout',{staticClass:"column is-full",attrs:{"mq":['mobile', 'tablet']}},[_c('div',{class:{ 'px-6': ['tablet'].includes(_vm.$mq) }},[(['tablet', 'desktop'].includes(_vm.$mq))?_c('div',{staticClass:"section-two"}):_vm._e(),_c('div',{staticClass:"title is-6 has-text-right-mobile has-text-right-tablet"},[_vm._v(" ...fühle ich mich im "),_c('span',{staticClass:"has-text-primary is-family-secondary"},[_vm._v("Pott")]),_vm._v(" bestens aufgehoben. ")])])]),_c('div',{staticClass:"column is-one-third-desktop is-relative is-offset-2-desktop"},[_c('mq-layout',{staticClass:"py-6 my-6",attrs:{"mq":['desktop']}}),_c('div',{staticClass:"is-flex is-justify-content-flex-end is-relative",class:{
              'px-6': ['tablet'].includes(_vm.$mq),
              'px-4': ['desktop', 'widescreen', 'fullhd'].includes(_vm.$mq),
              'py-4': ['mobile', 'tablet'].includes(_vm.$mq),
            }},[(['desktop', 'widescreen', 'fullhd'].includes(_vm.$mq))?_c('canvas-3-d',{staticClass:"offset-canvas is-absolute",staticStyle:{"width":"800px","height":"800px"}}):_vm._e(),_c('div',{style:(`width: ${!['mobile', 'tablet', 'desktop'].includes(_vm.$mq) ? '400px' : '100%'}`)},[_c('div',{staticClass:"is-retro has-background-light px-4",attrs:{"id":"sc-valley__img-2"}},[_c('b-image',{attrs:{"src":require('@/assets/img/kevin-cutout.png'),"ratio":"is5by7","alt":"Kevin Kössl - Full Stack Developer"}})],1)])],1),(['mobile', 'tablet'].includes(_vm.$mq))?[_c('div',{staticClass:"section-two"}),_c('canvas-3-d')]:_vm._e()],2),_c('mq-layout',{staticClass:"column is-half is-flex-desktop is-align-items-center",attrs:{"mq":['desktop', 'widescreen']}},[_c('div',{staticClass:"title is-6"},[_vm._v(" ...fühle ich mich im "),_c('span',{staticClass:"has-text-primary is-family-secondary"},[_vm._v("Pott")]),_vm._v(" bestens aufgehoben. ")])]),_c('mq-layout',{staticClass:"column is-half",attrs:{"mq":['fullhd']}},[_c('text-slide',{attrs:{"animated-text":_vm.heroText2}}),(_vm.$mq === 'tablet')?_c('div',{staticClass:"section-two"}):_vm._e()],1)],1),_c('div',{attrs:{"id":"sc-valley__img-2-end-trigger"}})])]),_c('div',{staticClass:"section-five"})])
}
var staticRenderFns = []

export { render, staticRenderFns }